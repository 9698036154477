@import "./_colors.scss";
@import "./_fonts.scss";
@import "./breakpoints.scss";

.hero_container {
	height: auto;
	background-color: $White;
}

.hero_wrapper {
	padding: 6rem 0;
	margin: 0px 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	@include mobile {
		margin: 0px 0.4rem;
	}

	> .hero_heading {
		padding: 6rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1.5rem;
		width: 47.6875rem;
		z-index: 1;

		@include mobile {
			padding: 0rem;
			width: 20rem;
		}

		> h1 {
			color: #333;
			text-align: center;
			font-family: $Montserrat;
			font-size: 4rem;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			@include mobile {
				width: 20rem;
				margin: 0;
			}
		}

		> p {
			color: #636363;
			text-align: center;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 180%; /* 1.6875rem */

			@include mobile {
				width: 20rem;
			}
		}

		> button {
			display: inline-flex;
			padding: 0.875rem 1.875rem;
			justify-content: center;
			align-items: center;
			gap: 0.625rem;
			border-radius: 1.875rem;
			border: none;
			background: #ffd726;

			color: $Black;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}

	> .hero_bacground_images {
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		margin: 6rem 0;
		gap: 6rem;

		> img {
			width: 78.125rem;
			height: 16.49625rem;
			margin: 0;
		}
	}

	> .hero_background_shapes {
		width: 100%;
		height: 100%;
		margin: 6rem 0;
		display: flex;
		justify-content: center;
		align-items: center;

		> img {
			width: 72.6875rem;
			height: 16.75163rem;
			flex-shrink: 0;

			@include mobile {
				width: 20rem;
				height: auto;
			}
		}
	}
}
