@import "./_colors.scss";
@import "./_fonts.scss";
@import "./breakpoints.scss";

.facilitiescard_card {
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;

	> div {
		gap: 1rem;

		> h3 {
			color: $Heading-color;
			font-family: $Montserrat;
			font-size: 1.125rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-align: center;
			margin-bottom: 0.44rem;
		}

		> p {
			width: 16.8125rem;
			color: $Paragraph-color;
			text-align: center;
			font-family: $Poppins;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 180%; /* 1.575rem */
		}
	}
}
