@import "./_colors.scss";
@import "./_fonts.scss";
@import "./breakpoints.scss";

.facilities {
	&_container {
		height: auto;
		background-color: $White;
	}

	&_wrapper {
		padding: 6rem 0;
		margin: 0px 5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include mobile {
			margin: 0px 0.4rem;
			gap: 4rem;
		}
	}

	&_header {
		width: auto;
		padding: 6rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.44rem;

		@include mobile {
			padding: 0rem;
		}

		> h3 {
			color: $Heading-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 180%;
		}

		> h1 {
			width: 30.5rem;
			color: $Heading-color;
			text-align: center;
			font-family: $Montserrat;
			font-size: 2.25rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@include mobile {
				width: 20rem;
				margin: 0;
			}
		}
	}

	&_content {
		display: flex;
		width: 65.4375rem;
		align-items: flex-start;
		align-content: flex-start;
		gap: 6.25rem 7.5rem;
		flex-wrap: wrap;

		@include mobile {
			width: 20rem;
			gap: 4rem;
			align-items: center;
			justify-content: center;
		}
	}
}
