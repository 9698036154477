@import "./_breakpoints.scss";
@import "./_colors.scss";
@import "./_fonts.scss";

.communitysection {
	&_container {
		height: 100vh;
		background-color: $White;

		@include mobile {
			height: auto;
		}
	}

	&_wrapper {
		padding: 6rem 0;
		margin: 0px 5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;

		@include mobile {
			margin: 0px 1.4rem;
			gap: 4rem;
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		width: 49.625rem;
		height: 25.4375rem;
		border-radius: 1.125rem;
		box-shadow: 0px 0px 117px 3px rgba(252, 68, 15, 0.08);
		background: $Card-bg;
		flex-shrink: 0;
		z-index: 1;

		@include mobile {
			width: auto;
			height: auto;
			flex-shrink: 0;
		}

		&_title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			> h3 {
				color: #333;
				font-family: $Poppins;
				font-size: 0.9375rem;
				font-style: normal;
				font-weight: 400;
				line-height: 180%; /* 1.6875rem */
			}

			> h1 {
				width: 30.5rem;
				font-family: $Montserrat;
				font-size: 2.25rem;
				font-weight: 600;
				line-height: normal;
				text-align: center;

				@include mobile {
					width: 20.0625rem;
				}
			}
		}

		> p {
			width: 31.5625rem;
			color: #636363;
			text-align: center;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 170%; /* 1.59375rem */

			@include mobile {
				width: 20.0625rem;
			}
		}

		> button {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border: none;
			background-color: transparent;
			gap: 0.25rem;
			color: $Secondary-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}

	&_backgroungimg {
		position: absolute;
		height: 100%;
		width: 100%;

		> .star1 {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 10%;
			left: 90%;
		}

		> .star2 {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 80%;
			left: 10%;
		}
	}
}
