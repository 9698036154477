@import './fonts';
@import './breakpoints';

div.about__card {
    width: 29.5625rem;
    height: auto;
    display: flex;
    flex-direction: column;

    img {
        align-self: center;
        // margin-bottom: 4.12rem;


        @include mobile {
            align-self: flex-start;
        }
    }

    h6 {
        margin-top: 4rem;


        @include mobile {
            color: #333;
            font-family: $Poppins;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 180%; /* 1.6875rem */
            margin-bottom: 0;
        }
    }
    
    h3 {
        width: 29.5rem;
        color: #333;
        font-family: $Montserrat;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 2.8125rem */
        margin-bottom: 1rem;

        @include mobile {
            width: 19rem;
            color: #333;
            font-family:$Montserrat;
            font-size: 1.625rem;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 2.4375rem */
            margin-top: 0;
        }
        
    }
    p {
        position: relative;
        width: 29.5625rem;
        margin-bottom: 1rem;
        color: #636363;
        font-family: $Poppins;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 170%; /* 1.59375rem */


        @include mobile {
            font-size: 0.9375rem;
            width: 18rem;
        }
            }

    @include mobile {
        width: 100%;
    }
}