@import "./_colors.scss";
@import "./_breakpoints.scss";
@import "./_fonts.scss";

.nav__component {
	position: fixed;
	display: flex;
	z-index: 5;
	top: 0;
	right: 0;
	left: 0;
	justify-content: space-between;
	padding: 10px 5%;
	align-items: center;
	background-color: $White;
	transition: all 0.3s ease-in-out;
	color: white;

	@include mobile {
		padding: 10px 1%;
	}
}

.links__content {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 2.625rem;

	> button {
		color: $Nav-Button;
		font-family: $Poppins;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		border: none;
		background-color: transparent;
	}

	> .contact_button {
		display: flex;
		padding: 0.625rem 1.125rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 1.875rem;
		border: 1.5px solid $Base-color;
		background-color: transparent;
	}
}
