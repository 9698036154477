$background-offWhite: #dedede;
$bg-off: #f7f7f7;
$Black: #000;
$White: white;
$Base-color: #ffd726;
$Secondary-color: #fc440f;
$Paragraph-color: #636363;
$Heading-color: #333;
$Card-bg: #fff;
$Nav-Button: #828282;
$Hambuger-bg: #333;
