@import "./_breakpoints.scss";
@import "./_colors.scss";
@import "./_fonts.scss";

.testimonials {
	&_container {
		height: auto;
		background-color: $White;
	}

	&_wrapper {
		padding: 6rem 0;
		margin: 0px 5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 4.5rem;

		@include mobile {
			margin: 0px 0.4rem;
			gap: 4rem;
			align-items: center;
		}
	}

	&_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 0.4rem;

		@include mobile {
			padding: 0rem;
		}

		> h3 {
			color: #333;
			font-family: Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 180%; /* 1.6875rem */
		}

		> h1 {
			width: 24.0625rem;
			color: #333;
			font-family: Montserrat;
			font-size: 2.25rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@include mobile {
				width: 20rem;
				margin: 0;
				font-size: 1.5rem;
			}
		}
	}

	&_content {
		width: 100%;
		display: inline-flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 3.75rem;

		@include mobile {
			flex-direction: column;
			width: 20rem;
			gap: 4rem;
			justify-content: center;
			align-items: stretch;
		}
	}
}
