$breakpoints: (
	"mobile": 480px,
	"tablet": 768px,
	"landscape": 1199px,
);
@mixin mobile {
	@media screen and (max-width: map-get($breakpoints, "mobile")) {
		@content;
	}
}
@mixin tablet {
	@media screen and (max-width: map-get($breakpoints, "tablet")) {
		@content;
	}
}
@mixin landscape {
	@media screen and (max-width: map-get($breakpoints, "landscape")) {
		@content;
	}
}
