@import "./_colors.scss";
@import "./_fonts.scss";
@import "./breakpoints.scss";

.aboutus_container {
	height: auto;
	background-color: $bg-off;
}

.aboutus_wrapper {
	padding: 5rem 0;
	margin: 0px 5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 14rem;

	@include mobile {
		flex-direction: column;
		gap: 4rem;
		margin: 0 0.4rem;
	}

	> .aboutus_left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		gap: 2rem;
		width: 31.5625rem;

		@include mobile {
			gap: 1rem;
			width: auto;
		}

		> h3 {
			color: $Heading-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 180%; /* 1.6875rem */
		}

		> h2 {
			color: $Heading-color;
			font-family: $Montserrat;
			font-size: 2.25rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@include mobile {
				width: 100%;
				font-size: 1.5rem;
			}
		}

		> p {
			color: $Paragraph-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 170%; /* 1.59375rem */

			@include mobile {
				width: 100%;
				font-size: 0.875rem;
			}
		}

		> button {
			display: inline-flex;
			flex-direction: row;
			align-items: center;
			gap: 0.25rem;
			background-color: $bg-off;
			border: none;
			color: $Secondary-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}

	> .aboutus_right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		width: 31.5625rem;

		@include mobile {
			width: 22rem;
			height: 100%;
			flex-shrink: 0;
		}

		> img {
			width: 100%;
			height: 100%;

			@include mobile {
				width: 22rem;
				height: 22rem;
			}
		}
	}
}
