@import './fonts';
@import './breakpoints';

div.staff-card {
    margin-top:2.5rem;;
    width: 20%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    justify-content: space-between;

    img.portrait {

        width: 100%;
        border-radius: 1.25rem;
        background:  lightgray -213.07px -2.226px / 229.985% 147.018% no-repeat, #D9D9D9;

        @include mobile {
            width: 13.9375rem;
            height: 14.125rem;
            align-self: center;
        }
    }

    span.text {
        h3 {
            color: #333;
            font-family: $Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 170%; /* 1.7rem */
        }
    
        h4 {
            color: #333;
            font-family: $Poppins;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
        }
    }
}