@import './breakpoints';
@import './fonts';

div.about-origin {
        overflow-x: hidden;
    @include mobile {
        overflow-x: visible;
        flex-wrap: wrap;
        width: 100vw;
        display: flex;
        align-content: center;
        padding: 0;
    }

}
div.about__body {
    @include mobile {
        padding: 0;
    }
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 6rem;
    &__top {
        display: flex;
        align-self: center;

        @include mobile {
            flex-direction: column;
            margin-top: 9.5rem;
            width: 23.375rem;

            img {
                display: none;
            }
        }
        
        &__text-container {

            @include mobile {
                width: 23.775rem;
                align-self: center;
            }
            width: 46.6875rem;
            height: 13rem;
            justify-content: center;

            h6 {
                text-align: center;
            }
            p {
                color: #333;
                text-align: center;
                font-family: $Montserrat;
                font-size: 2rem;
                word-wrap: normal;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 4.5rem */ 
            }
            
        }

        img.star {
            margin-top: 11rem;
        }

    }

    div.about__body__mission {

        @include mobile {
            flex-direction: column;
            align-self: center;
            padding: 1rem;
        }
        align-self: center;
        margin-top: 11rem;
        display: flex;
        flex-direction: row;
        gap: 18rem;
        margin-right: 0;
    }

    &__founder {

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            
            img {
                width: 13.9375rem;
                height: auto;
                align-self: flex-start;
            }

            div.text-container {
                p.quote {
                    width: 21.375rem;
                    font-size: 1.375rem;
                    
                    
                }   

                @include mobile {
                    h6 {
                        margin-bottom: 0;
                    }

                    h3 {
                        margin-top: 0;
                    }
                }
            }

        }
        margin-top: 10rem;
        display: inline-flex;
        gap: 2.5rem;
        align-self: flex-start;
        padding-left: 2rem;
        
        img {
            border: 1px solid #333;
        }
        p.quote {
            width: 30.125rem;
            color: #333;
            font-family: $Montserrat;
            font-size: 1.675rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 2.8125rem */
        }

        h6.founders-name {
            margin-top: 1rem;
            color: #333;
            font-family: $Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 170%; /* 1.7rem */
        }

        h3.founders-role {
            color: #333;
            font-family: $Poppins;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: normal;
            line-height: 170%; /* 1.59375rem */
        }
    }

    &__staff {
        margin-top: 10.84rem;
        width: 100%;
        display: flex;
        flex-direction: column;


        
        &__grid {

            @include mobile {
                flex-direction: column;
                align-content: center;
            }
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: .01rem;
        }

        &__text {

           
            display: inline-flex;
            flex-direction: column;
            h3 {
                align-self: center;
                color: #333;
                text-align: center;
                font-family: $Montserrat;
                font-size: 2.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }

            p {
                width: 17rem;
                color: #636363;
                text-align: center;
                font-family: $Poppins;
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 400;
                line-height: 180%; /* 1.6875rem */
                align-self: center;
                margin-bottom: 1.88rem;
            }

            @include mobile {
                align-self: center;


                h3 {
                    margin-bottom: 1rem;
                    color: #333;
                    text-align: center;
                    font-family: $Montserrat;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 2.25rem */
                }

                p {
                    margin-top: 0;
                    align-self: stretch;
                    color: #636363;
                    text-align: center;
                    font-family: $Poppins;
                    font-size: 0.9375rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 180%; /* 1.6875rem */
                }
            }
        }

        @include mobile {
            color: #333;
            text-align: center;
            font-family: $Montserrat;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 2.25rem */
            align-items: start;
        }
    }

    &__partners {
        margin-top: 10rem;
        display: flex;
        gap: 1.25rem;
        justify-content: space-between;
        flex-direction: column;

        h1 {
            align-self: center;
            color: #333;
            text-align: center;
            font-family: $Montserrat;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }

        &__container {
            align-self: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 3rem;


            &__brand1 {
                display: flex;
                flex-direction: column;
                img {
                    margin-top: 1rem;
                    align-self: center;
                    width: 4.565rem;
                    height: auto;
                    background-size: cover ;
                }

                h6 {
                    width: 4.375rem;
                    text-align: center;
                    align-self: center;
                    
                }
            }

            &__brand {
                img {
                    margin-left: 1rem;
                    align-self: center;
                    width: 4.565rem;
                    height: auto;
                    background-size: cover ;
                }

                h6 {
                    width: 6.375rem;
                    text-align: center;
                    align-self: center;
                    
                }
            }

        }

        @include mobile {

            align-self: center;

            h1 {
                align-self: center;
                font-size: 2rem;
                
            }

            &__container {
                flex-direction: column;
            }
        }
    }

    
}