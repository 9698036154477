@import "./_breakpoints.scss";
@import "./_colors.scss";
@import "./_fonts.scss";

.services-card {
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;

	> img {
		width: 100%;
		object-fit: contain;
	}

	> h3 {
		align-self: stretch;
		color: $Heading-color;
		font-family: $Montserrat;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	> p {
		align-self: stretch;
		color: $Paragraph-color;
		font-family: $Poppins;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 180%; /* 1.575rem */
	}
}
