@import "./_breakpoints.scss";
@import "./_colors.scss";
@import "./_fonts.scss";

.footer {
	&_container {
		height: auto;
		background-color: $White;
	}

	&_wrapper {
		padding: 6rem 0 0 0;
		margin: 0px 5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4.5rem;

		@include mobile {
			margin: 0 0.4rem;
			padding: 0rem 0;
		}

		> header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 11.25rem;
			width: 100%;

			@include mobile {
				flex-direction: column;
				gap: 2.88rem;
				align-items: flex-start;
			}

			> .footer_left {
				display: flex;
				flex-direction: column;
				gap: 6rem;
				align-items: flex-start;
				justify-content: space-between;

				@include mobile {
					width: 20rem;
				}

				> .footer_left_header {
					display: inline-flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 1.0625rem;

					@include mobile {
						display: inline-flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 1.25rem;
					}

					> h1 {
						width: 17.6875rem;
						color: $Heading-color;
						font-family: $Montserrat;
						font-size: 3rem;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}

					> p {
						width: 31.5625rem;
						color: $Paragraph-color;
						font-family: $Poppins;
						font-size: 0.9375rem;
						font-weight: 400;
						line-height: 170%;

						@include mobile {
							width: 19.0625rem;
						}
					}

					> button {
						border: none;
						padding: 0.875rem 1.875rem;
						border-radius: 1.875rem;
						background: $Base-color;
						gap: 0.625rem;
						align-items: center;
						justify-content: center;
						color: $Black;
						font-family: $Poppins;
						font-size: 0.9375rem;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
				}

				> .footer_left_footer {
					display: inline-flex;
					align-items: flex-start;
					gap: 2rem;

					@include mobile {
						display: flex;
						width: 21.5625rem;
						align-items: flex-start;
						align-content: flex-start;
						gap: 4.25rem 3.625rem;
						flex-wrap: wrap;
					}

					> .footer_left_footer_links {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 1rem;

						@include mobile {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							gap: 1rem;
						}

						> h3 {
							color: $Heading-color;
							font-family: $Montserrat;
							font-size: 1.125rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}

						> button {
							display: flex;
							border: none;
							background-color: transparent;
							align-content: flex-start;
							width: 6.4375rem;
							color: $Paragraph-color;
							font-family: $Poppins;
							font-size: 0.875rem;
							font-style: normal;
							font-weight: 400;
							line-height: 180%; /* 1.575rem */
						}
					}

					> .footer_left_footer_contact {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 1rem;

						@include mobile {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							gap: 1rem;
						}

						> h3 {
							color: $Heading-color;
							font-family: $Montserrat;
							font-size: 1.125rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}

						> p {
							width: 11.4375rem;
							color: $Paragraph-color;
							font-family: $Poppins;
							font-size: 0.875rem;
							font-style: normal;
							font-weight: 400;
							line-height: 180%; /* 1.575rem */
						}
					}

					> .footer_left_footer_address {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 1rem;

						@include mobile {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							gap: 1rem;
						}

						> h3 {
							color: $Heading-color;
							font-family: $Montserrat;
							font-size: 1.125rem;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}

						> p {
							width: 11.4375rem;
							height: 6.0625rem;
							color: $Paragraph-color;
							font-family: $Poppins;
							font-size: 0.875rem;
							font-style: normal;
							font-weight: 400;
							line-height: 180%; /* 1.575rem */
						}
					}
				}
			}

			> .footer_right {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 28.5rem;
				height: 100%;

				@include mobile {
					width: 17.95438rem;
					height: 16.8125rem;
					flex-shrink: 0;
				}
			}
		}

		> footer {
			display: inline-flex;
			height: 4.5rem;
			padding: 0rem 17.375rem 0rem 17.4375rem;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-top: 1px solid rgba(0, 0, 0, 0.15);
			background: #fcfcfc;

			@include mobile {
				display: inline-flex;
				padding: 0.875rem 2.625rem 0.875rem 2.6875rem;
				justify-content: center;
				align-items: center;
				margin: 0;
			}

			> .footer_center {
				display: flex;
				align-items: center;

				@include mobile {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 0.625rem;
					width: 100%;
				}

				> p {
					color: rgba(0, 0, 0, 0.39);
					font-family: $Poppins;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 1.225rem */

					@include mobile {
						font-size: 0.6rem;
						margin: 0;
					}
				}

				> img {
					width: 0.25rem;
					height: 0.25rem;
					fill: #919191;
				}

				> h3 {
					color: rgba(0, 0, 0, 0.39);
					font-family: $Poppins;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 1.225rem */

					@include mobile {
						margin: 0;
						font-size: 0.75rem;
					}

					> span {
						color: rgba(0, 0, 0, 0.39);
						font-family: $Poppins;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 700;
						line-height: 140%;

						@include mobile {
							font-size: 0.75rem;
						}
					}
				}
			}
		}
	}
}
