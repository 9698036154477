@import "./_breakpoints.scss";
@import "./_colors.scss";
@import "./_fonts.scss";

.testimonials_card {
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.19rem;

	> h3 {
		color: $Paragraph-color;
		font-family: $Poppins;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 170%; /* 1.4875rem */
	}

	> p {
		width: 15.9375rem;
		color: $Heading-color;
		font-family: $Poppins;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 170%; /* 1.4875rem */
	}
}
