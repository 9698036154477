@import './fonts';
@import './breakpoints';

div.faqpage {
    display: flex;
    flex-direction: column;

    @include mobile {
        width: 100%;
    }
    &__header {
        margin-top: 10rem;
        color: #333;
        text-align: center;
        font-family: $Montserrat;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 2.8125rem */

        @include mobile {
            font-size: 1.5rem;
            margin-bottom: 0;
        }
    }

    &__body {
        margin-top: 3rem;
        width: 44rem;
        align-self: center;
        &__item {
            border: 0.1px solid rgb(175, 189, 189);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2rem;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-top: 1.875rem;
            // padding-left: 1.875rem;
            // padding-right: 1.875rem;
            padding-bottom: 1.25rem;

            &__question {

                h1 {
                    width: 23rem;

                    @include mobile {
                        width: 15rem;
                        line-height: 150%;
                    }
                }

                img {
                    background-color: white;
                }

                height: auto;
                display: flex;
                justify-content: space-between;
                color: #333;
                font-family: $Poppins;
                font-size: 0.5125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 170%; /* 1.9125rem */

                @include mobile {
                    font-size: .4rem;
           
                }
            
            }

            &__answer {
                width: 25em;
                color: #333;
                font-family: $Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 170%; /* 1.7rem */

                @include mobile {

                    p {
                        width: 15rem;
                    }

                }
            }
        }

        @include mobile {
            width: 23.3125rem;
            align-self: center;
            margin-top: 0;

        }
    }


}