@import "./_breakpoints.scss";
@import "./_colors.scss";
@import "./_fonts.scss";

.service {
	&_container {
		height: auto;
		background-color: $bg-off;
	}

	&_wrapper {
		padding: 6rem 0;
		margin: 0px 5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 4.5rem;

		@include mobile {
			margin: 0px 0.4rem;
			gap: 4rem;
			align-items: center;
		}
	}

	&_header {
		width: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 0.44rem;

		@include mobile {
			padding: 0rem;
		}

		> h3 {
			color: $Heading-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 400;
			line-height: 180%; /* 1.6875rem */
		}

		> h1 {
			width: 31.6875rem;
			color: $Heading-color;
			font-family: $Montserrat;
			font-size: 2.25rem;
			font-weight: 600;
			line-height: normal;

			@include mobile {
				width: 20rem;
				margin: 0;
				font-size: 1.5rem;
			}
		}
	}

	&_content {
		display: inline-flex;
		align-items: flex-start;
		gap: 3.5rem;

		@include mobile {
			flex-direction: column;
			width: 20rem;
			gap: 4rem;
			justify-content: center;
			align-items: stretch;
		}
	}

	&_footer {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		> button {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border: none;
			background-color: transparent;
			gap: 0.25rem;
			color: $Secondary-color;
			font-family: $Poppins;
			font-size: 0.9375rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
}
